import React, { FC } from 'react';
import { graphql, PageProps } from 'gatsby';

import { IBreadCrumb } from '@shared/types';

import { Layout, Container } from 'layout';

import ArticleHeader from 'components/ArticleHeader';
import ArticleBody from 'components/ArticleBody';
import ArticleReferences from 'components/ArticleReferences';
import BreadCrumbs from 'common/BreadCrumbs';
import ProductsFinder from 'components/ProductsFinder';
import FeaturedArticles from 'components/FeaturedArticles';

import { isNotEmptyArray } from 'utils/array';

import { IPropsArticlePage } from './models';
import './ArticlePage.scss';

interface IProps extends PageProps {
  pageContext: { breadcrumbs: IBreadCrumb[]; lang: string };
  data: IPropsArticlePage;
}

const ArticlePage: FC<IProps> = ({
  pageContext: { breadcrumbs, lang },
  data: {
    article: {
      seo,
      defaultCompositions,
      articleHeroBanner,
      articleTableOfContentsTitle,
      articleBody,
      tags,
      featuredArticlesList,
      articleReferenceSection,
      startQuiz,
      selectedProductsIds,
      createDate,
      updateDate,
    },
    featuredArticlesAboutStrepsilsByLink,
    featuredArticlesAboutStrepsilsByTag,
    featuredArticlesSymptomsByLink,
    featuredArticlesSymptomsByTag,
    featuredArticlesByLink,
    featuredArticlesByTag,
    featuredProductsByLinkInBody,
    featuredProductsByTagInBody,
    articleSettings,
  },
}) => {
  const { title, image, heroBannerImageAltText } = articleHeroBanner[0].properties;

  return (
    <Layout
      defaultCompositions={defaultCompositions}
      seo={seo}
      anefpBlockCustomization={{ isHideBlock: true }}
    >
      <Container>
        <BreadCrumbs breadcrumbs={breadcrumbs} />
      </Container>
      <Container>
        <ArticleHeader
          tags={tags}
          title={title}
          mainArticleImage={image}
          mainArticleImageAltText={heroBannerImageAltText}
        />
        <ArticleBody
          articleBody={articleBody}
          articleTableOfContentsTitle={articleTableOfContentsTitle}
          defaultCompositions={defaultCompositions}
          lang={lang}
          featuredProductsByLinkInBody={featuredProductsByLinkInBody}
          featuredProductsByTagInBody={featuredProductsByTagInBody}
          selectedProductsIds={selectedProductsIds}
          articleSettings={articleSettings}
          createDate={createDate}
          updateDate={updateDate}
        />
        {articleReferenceSection[0] ? (
          <ArticleReferences articleReferenceSection={articleReferenceSection[0]} />
        ) : null}
      </Container>
      {isNotEmptyArray(startQuiz) ? (
        <div className="products--finder__wrapper">
          <Container>
            <ProductsFinder
              startQuiz={startQuiz}
              productQuizData={defaultCompositions.productQuiz}
              ariaLabelPrev={defaultCompositions.siteSettings.ariaLabelPrev}
              ariaLabelNext={defaultCompositions.siteSettings.ariaLabelNext}
              lang={lang}
            />
          </Container>
        </div>
      ) : null}
      {isNotEmptyArray(featuredArticlesList) ? (
        <FeaturedArticles
          featuredArticlesList={featuredArticlesList}
          featuredArticlesByLink={[
            ...featuredArticlesAboutStrepsilsByLink.nodes,
            ...featuredArticlesSymptomsByLink.nodes,
            ...featuredArticlesByLink.nodes,
          ]}
          featuredArticlesByTag={[
            ...featuredArticlesAboutStrepsilsByTag.nodes,
            ...featuredArticlesSymptomsByTag.nodes,
            ...featuredArticlesByTag.nodes,
          ]}
          dir={defaultCompositions.siteSettings.dir[0]}
          ariaLabelPrev={defaultCompositions.siteSettings.ariaLabelPrev}
          ariaLabelNext={defaultCompositions.siteSettings.ariaLabelNext}
        />
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query ArticlePage(
    $link: String = ""
    $lang: String!
    $selectedArticlesLinks: [String]
    $selectedArticlesTag: Int
    $featuredProductsURLsInBody: [String]
    $featuredProductsTagInBody: Int
  ) {
    article(link: { eq: $link }) {
      updateDate
      createDate
      seo {
        ...FragmentSeo
      }
      defaultCompositions {
        ...FragmentDefaultCompositions
      }
      articleHeroBanner {
        properties {
          title
          showTitleMobile
          image {
            ...FragmentGatsbyImage
          }
          heroBannerImageAltText
        }
      }
      articleTableOfContentsTitle
      articleBody {
        ...FragmentArticleBody
      }
      articleReferenceSection {
        ...FragmentArticleReference
      }
      startQuiz {
        ...FragmentStartQuizSection
      }
      featuredArticlesList {
        ...FragmentFeaturedArticles
      }
      tags {
        ...FragmentArticleCategoryTag
      }
      selectedProductsIds: featuredProductsURLsInBody {
        id
      }
    }
    featuredArticlesAboutStrepsilsByLink: allArticleAboutStrepsils(
      filter: { link: { in: $selectedArticlesLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentArticleAboutStrepsilsCardRelated
      }
    }
    featuredArticlesAboutStrepsilsByTag: allArticleAboutStrepsils(
      filter: { tags: { elemMatch: { id: { eq: $selectedArticlesTag } } }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentArticleAboutStrepsilsCardRelated
      }
    }
    featuredArticlesSymptomsByLink: allArticleSymptoms(
      filter: { link: { in: $selectedArticlesLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentArticleSymptomsCardRelated
      }
    }
    featuredArticlesSymptomsByTag: allArticleSymptoms(
      filter: { tags: { elemMatch: { id: { eq: $selectedArticlesTag } } }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentArticleSymptomsCardRelated
      }
    }
    featuredArticlesByLink: allArticle(
      filter: { link: { in: $selectedArticlesLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentArticleCardRelated
      }
    }
    featuredArticlesByTag: allArticle(
      filter: { tags: { elemMatch: { id: { eq: $selectedArticlesTag } } }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentArticleCardRelated
      }
    }
    featuredProductsByLinkInBody: allProduct(
      filter: { link: { in: $featuredProductsURLsInBody }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentProductCardRelated
      }
    }
    featuredProductsByTagInBody: allProduct(
      filter: {
        tags: { elemMatch: { id: { eq: $featuredProductsTagInBody } } }
        lang: { eq: $lang }
      }
    ) {
      nodes {
        ...FragmentProductCardRelated
      }
    }
    articleSettings(lang: { eq: $lang }) {
      ...FragmentArticleSettings
    }
  }
`;

export default ArticlePage;
