import React, { FC } from 'react';

import ArticleTag from 'components/ArticleTag';
import ArticleImage from 'components/ArticleImage';

import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import { getUmbracoUrl } from 'utils/transformers';

import { ArticleHeaderProps } from './models';
import './ArticleHeader.scss';

const ArticleHeader: FC<ArticleHeaderProps> = ({
  tags,
  title,
  mainArticleImage,
  mainArticleImageAltText,
}) => (
  <div data-test="ArticleHeader" className="article-header">
    <h1 data-test="ArticleHeaderTitle" className="article-header__title">
      <DangerouslySetInnerHtml html={title} className="content__title" />
    </h1>
    {tags ? (
      <div data-test="ArticleTagsWrapper" className="article-header__tag-wrapper">
        {tags.map((tag) => (
          <ArticleTag key={tag.name} label={tag.name} url={getUmbracoUrl(tag.url)} />
        ))}
      </div>
    ) : null}
    <ArticleImage
      dataTest="ArticleHeaderImage"
      image={mainArticleImage}
      alt={mainArticleImageAltText}
      objectFit="cover"
    />
  </div>
);

export default ArticleHeader;
